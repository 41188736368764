import React from 'react';
import './App.css';
import NewsArticle from './NewsArticle';
import SearchForm from './SearchForm';

function App() {
  return (
    <div>
      <div className="bg-marquee top"></div> 
      <div className="container">
          <div className="newspaper">
              <NewsArticle
                  title="Florida Man Breaks into a House"
                  content="Lorem ipsum dolor sit amet..."
                  imageUrl="assets/images/newspaper-image-1.jpg"
              />
          </div>
      </div>
      <div className="meme alligator">
          <img src="assets/images/florida-memes/5.png" alt="" />
      </div>
      <div className="meme wtf">
          <img src="assets/images/florida-memes/2.png" alt="" />
      </div>
      <div className="meme dating">
          <img src="assets/images/florida-memes/3.jpg" alt="" />
      </div>
      <div className="meme hunting">
          <img src="assets/images/florida-memes/4.png" alt="" />
      </div>
      <div className="meme willie">
          <img src="assets/images/florida-memes/6.png" alt="" />
      </div>
      <div className="bg-marquee"></div> 
    </div>

  );
}

export default App;