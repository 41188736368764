import React, { useState, useEffect } from "react";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";

import localeEn from "air-datepicker/locale/en";

function SearchForm() {
  const [inputDate, setInputDate] = useState("");
  const [resultText, setResultText] = useState("");
  const [htmlContent, setHtmlContent] = useState({ __html: "" });

  //exclude domains:
  const excludedDomains = [
    "instagram.com",
    "twitter.com",
    "reddit.com",
    "sheriff.org",
    "fbi.gov",
    "tsa.gov",
    "dhs.gov",
    "dol.gov",
  ];

  useEffect(() => {
    new AirDatepicker("#birthdate", {
      minDate: "01/01/2024",
      maxDate: "12/31/2024",
      autoClose: true,
      minView: "days",
      view: "days",
      locale: localeEn,
    });
  }, []);

  const searchFloridaMan = async () => {
    const birthdateElement = document.getElementById("birthdate");
    const inputDateString = birthdateElement.value;

    if (!inputDateString.trim()) {
      setResultText("Select a date before searching.");
      return;
    }

    try {
      const inputDate = new Date(inputDateString);
      if (isNaN(inputDate.getTime())) {
        // Check if the date is invalid
        setResultText("Invalid date format.");
        return;
      }

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const formattedDate = `${inputDate.getDate()} ${
        monthNames[inputDate.getMonth()]
      }`;

      setResultText("Searching...");
      await new Promise((resolve) => setTimeout(resolve, 0));

      const searchQuery = "Florida Man " + formattedDate;
      const url = `https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(
        searchQuery
      )}&key=AIzaSyDKv8O0dy50k2AJG-0hMQEYl1mSczEgn5c&cx=e28433f7275bc4c34`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.items && data.items.length > 0) {
          const filteredItems = data.items.filter(
            (item) =>
              !excludedDomains.some((domain) => item.link.includes(domain))
          );

          if (filteredItems.length > 0) {
            const randomIndex = Math.floor(
              Math.random() * Math.min(filteredItems.length, 5)
            );
            const luckyUrl = filteredItems[randomIndex].link;

            const newWindow = window.open("", "_blank");
            if (newWindow) {
              newWindow.location = luckyUrl;
              setResultText("Found a story! Opening...");
            } else {
              setHtmlContent({
                __html: `Popup blocked. View <a href="${luckyUrl}" target="_blank">the story here</a>.`,
              });
            }
          } else {
            setResultText(
              "No stories found for this date without excluded domains."
            );
          }
        } else {
          setResultText("No stories found for this date.");
        }
      } catch (error) {
        console.error("Error:", error);
        setResultText("Failed to fetch stories.");
      }
    } catch (error) {
      console.error("Error:", error);
      setResultText("Failed to parse the date.");
    }
  };

  return (
    <div className="find-your-man">
      <h4>Find Your Florida Man</h4>
      <input
        type="text"
        id="birthdate"
        className="birthdate"
        placeholder="Enter a date"
        value={inputDate}
        onChange={(e) => setInputDate(e.target.value)}
      />
      <button id="searchButton" onClick={searchFloridaMan}>
        Find
      </button>
      <div id="result">{resultText}</div>
      <div class="result" dangerouslySetInnerHTML={htmlContent}></div>
    </div>
  );
}

export default SearchForm;