import React, { useEffect } from 'react';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import SearchForm from './SearchForm';
import DateDisplay from './DisplayDate';

import localeEn from 'air-datepicker/locale/en';

const NewsArticle = ({ title, content, imageUrl }) => {
    useEffect(() => {
        new AirDatepicker('#birthdate', {
            minDate: '01/01/2024',
            maxDate: '12/31/2024',
            autoClose: true,
            minView: 'days',
            view: 'days',
            locale: localeEn
        });
    }, []);

    return (
        <div>
            <div className="top">
                <div className="volume">Vol No. 1</div>
                <DateDisplay />
                <div className="type">News for the day</div>
            </div>
            <div className="title">
                <h1>The Daily Floridian</h1>
            </div>
            <div className="sub-title">
                Crypto prices are on the rise!
                <div className="socials">
                    <span className="socials-text">#Socials</span>
                    <a href="https://twitter.com/FloridaMan_SOL"><img src="assets/images/socials/x.png" alt="Social icon for X" /></a>
                    <a href="https://t.me/floridamangg"><img src="assets/images/socials/telegram.png" alt="Social icon for Telegram" /></a>
                    <a href="https://dexscreener.com/solana/FeH8FWk3i7Pz67MW6NgTkbbY2S3aykJ7UP3F6eiH2biv"><img src="assets/images/socials/dexscreener.png" alt="Social icon for Dexscreener" /></a>
                </div>
            </div>
            <div className="content">
                <div className="columns">
                    <div className="column-left">
                        <h3>Firefighter</h3>
                        <p>
                        <strong>
                        Florida man breaks into a house, cleans it and leaves behind origami.
                        </strong>
                        </p>
                        <p>
                        In 2019, a man identified as Nate Roman from Marlborough, Massachusetts, USA, reported a funny incident that left many shocked. He came home and found an intruder had entered his home and cleaned everything in the house, including spreading his bead and scrubbing the toilets. What is even more crazy is that he left behind origami roses on his toilet paper rolls.
                        </p>
                        <img src="assets/images/newspaper-image-1.jpg" alt="" />
                        <strong>
                        Florida Man blows a .339 blood alcohol level and gets a DUI on a golf cart
                        </strong>
                        <p>
                        Alfred Constant Mathieu went for a cruise on his golf cart, and while driving on a highway, he was stopped by police who noticed he was drunk. On testing his blood alcohol content, it turned out to be .339, which is way above the legal limit, and he was arrested.
                        </p>

                    </div>
                    <div className="column-middle">
                        <h2>Florida Man</h2>
                        <p>
                        Cops pulled over a driver they noticed was driving a vehicle without two wheels.

                        </p>
                        <img src="assets/images/florida-man.png" alt="" />
                        
                        <SearchForm />

                        <div className="column-bottom">
                            <img src="assets/images/newspaper-image-4.jpg" alt="" />
                            <p>
                            When the storm hasn't even hit yet and you already at all of your hurrican snacks.
                            </p>
                        </div>
                    </div>
                    <div className="column-right">
                        <img src="assets/images/newspaper-image-3.jpg" alt="" />
                        <h3>Fashion News</h3>
                        <p>
                        This Florida Man was taken into custody in October for driving a truck painted similar to a U.S. Customs and Border Patrol vehicle, but instead read “Booty Patrol.”
                            </p><p>
                        The incident report states that deputies discovered the driver had “multiple run-ins” with law enforcement due to his truck and even has a TikTok account for his vehicle.
                            </p><p>
                        The sheriff’s office asked drivers to keep an eye out for the “Booty Patrol” vehicle as it had been spotted in several other Florida counties as well.
                        </p>
                        <p>
                            <span className="page">Page No. 2</span>
                        </p>
                        <hr />
                        <img src="assets/images/newspaper-image-2.jpg" alt="" />
                        <h3>Political News</h3>
                        <p>
                        A New Port Richey man was arrested on a felony charge after authorities said he threw a hot dog
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsArticle;