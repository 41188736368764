import React, { useState, useEffect } from 'react';

function DateDisplay() {
    const [currentDate, setCurrentDate] = useState('');

    useEffect(() => {
        const date = new Date();
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        setCurrentDate(date.toLocaleDateString('en-US', options)); // formats date as "May 7, 2024"
    }, []);

    return (
        <div className="date">{currentDate}</div>
    );
}

export default DateDisplay;